.wrapper {
    padding-top: 75px;
    overflow-y: auto;
    overflow-x: hidden;
    /*padding-bottom:130px;*/
    min-height: calc(100vh - 0px);
}

.fullHeightWrapper {
    min-height: 0px
}

.fullPageWrapper {
    min-height: 100vh;
    padding-bottom: 10px;
}

.color-box {
    float: left;
    height: 1em;
    width: 1em;
    margin-bottom: 0px;
    margin-top: 4px;
    margin-right: 5px;
    border: 1px solid black;
    clear: both;
}

#search {
    display: flex;
    justify-content: center;
    margin: auto;
}

#search-left {
    display: flex;
    justify-content: left;
    margin: 0px;
}

#search h2 {
    padding: 0px 10px;
    font-size: 1.5rem;
}

#search-left h2 {
    padding: 0px 10px;
    font-size: 1.5rem;
}

.search-label {
    width: 90%;
}

.smallSearch .search-label {
    width: inherit;
}

.wrapper h1 {
    /*margin:20px 10px 30px 10px;*/
    font-size: 2rem;
}

.errorTable {
    font-size: 1.5rem;
    margin: 15px;
    color: red;
}

.header-hide-filter label {
    display: none
}

#filter-group {

    display: block;

}

#table {
    display: block;

}

#filter-button {
    float: left;
    margin: 15px;
}

.custom-light {
    background-color: #e8e9ea;
}

.smallFont {
    font-size: .8rem;
}

.mediumFont {
    font-size: .9rem;
}

#orderSearch {
    width: 90%
}

.fixedTable>tbody {
    display: block;
    height: 55vh;
    overflow-y: scroll;
    overflow-x: hidden
}


.fullContainer>tbody {
    height: calc(100vh - 488px) !important;
}

.fullContainerViewOrder>tbody {
    height: calc(100vh - 475px) !important;
    min-height: 200px;
}



.fixedTable>thead {
    display: table;
    width: calc(100% - 17px);
    table-layout: fixed;
}

@media (max-width: 550px) {
    .mobileTable>thead {
        width: calc(100% - 1.5px);
    }
}

.fixedTable>tbody>tr {
    display: table;
    width: 100%;
    table-layout: fixed
}

.mediumTable tbody {
    height: 40vh;
}

.mediumishTable tbody {
    height: 30vh;
}


.Right-item-column {


    padding: 10px;
    background-color: #fafafa;
    border: 1px solid lightgray;

}

.Right-cart-panel {
    width: 100%;

    height: 90vh;
    overflow-y: scroll;
}

.OrderStatusDropdown>button {

    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
}


.cartItem {
    width: 100%;
    margin-top: 10px;

}

.emptyItem {
    width: 100%;
    margin-top: 10px;

}

.orderItem {
    width: 100%;
    margin-top: 2px;
}

.LeftOrderPanel {
    max-height: calc(100vh - 170px);
}

.orderList {
    padding: 5px;
    margin-bottom: 10px;
    height: calc(100% - 150px);
    min-height: 300px;
    overflow-y: scroll;
    border: 1px solid lightgray;

}

.orderList:focus {
    outline: 0;
}

#popover-order-card-menu {
    background-color: #fafafa;
}

.cartItem:hover {
    filter: brightness(92%);
}

.orderItem-CREATED-false:hover {
    background-color: rgb(255, 193, 7, .05)
}

.orderItem-PLACED-false:hover {
    background-color: rgb(0, 123, 255, .05)
}

.orderItem-CLOSED-false:hover {
    background-color: rgb(40, 167, 69, .05)
}

.orderItem-PARTIAL_RECEIVED-false:hover {
    background-color: rgb(220, 53, 69, .05)
}

.orderItem-RECEIVED-false:hover {
    background-color: rgb(40, 167, 69, .05)
}

.orderItem-CREATED-true {
    background-color: rgb(255, 193, 7, .15)
}

.orderItem-PLACED-true {
    background-color: rgb(0, 123, 255, .15)
}

.orderItem-CLOSED-true {
    background-color: rgb(40, 167, 69, .15)
}

.orderItem-PARTIAL_RECEIVED-true {
    background-color: rgb(220, 53, 69, .15)
}

.orderItem-RECEIVED-true {
    background-color: rgb(40, 167, 69, .15)
}

#allToOrderButton {
    width: 100%;
}

#addItemButton {
    margin-top: 10px;
    width: 100%;
}

#tableWrapper {
    padding: 20px;
    max-width: 1500px;
    margin: auto;
}


.no-bottom {
    margin-bottom: 0px;
}

.no-bottom .form-label {
    margin-bottom: 0px;
}

.small-tight {
    margin: 10px !important;
}

.small-tight-h2 {
    font-size: 1.5rem;
}

.shortTable tbody {
    height: 20vh;
}

#selected-sku-label {
    font-size: 1.5rem;
}

#itemWrapper {
    margin-top: 10px;
}

.monospace {
    font-family: "Courier New", monospace;

}

.monospace h1 {
    font-size: 1rem !important
}

.monospace h2 {
    font-size: 1rem !important
}

.monospace h3 {
    font-size: 1rem !important
}

@media (min-width: 1200px) {
    .modal-dialog.modal-xl {
        max-width: 1000px;
    }
}

#tableWrapper .left {
    text-align: left;
    float: left
}

#tableWrapper .left {
    text-align: left;
    float: left
}

#tableWrapper .right {
    text-align: right;
    float: right
}

.table td {
    border-top: 0px
}

.table th {
    border-top: 1px dashed #dee2e6
}


#helpButton {
    position: absolute;
    top: 130px;
    right: 20px;
    z-index: 1001;
    background-color: #ededed;
    color: black;
}

#downloadItemWrapper {
    width: 80%;
    margin: auto
}

#toggleButton {
    float: right;
}

@media screen and (max-width: 768px) {
    #toggleButton {
        clear: both;
        float: none;
    }
}

.columnContainer {
    columns: 2 auto;
}

.columnContainer .form-group {
    margin-bottom: .5rem;
}


.grayTable {
    background-color: #fafafa
}

.partial-width {
    padding: 10px;
    max-width: 1200px;
    margin: auto
}

.order_url {
    color: blue
}

.order_url:hover {
    color: blue;
    filter: brightness(50%);
}

.order_url:visited {
    color: purple
}

.orderPreviewTableWrapper {
    background-color: #fafafa;
    padding-bottom: 20px;
}

.orderPreviewTableWrapper h3 {
    font-size: 1.2em;
}

.orderPreviewTable {
    max-width: 1000px;
    text-align: center;
    align-content: center;
    margin: auto;
    background-color: white;
}

.flex {
    display: flex;
    flex-direction: row;
}

.gutter.gutter-horizontal {
    cursor: ew-resize;
}

.tabbed-content {
    margin-right: 25px;
}

.tabbed-content h2 {
    font-size: 1.75em;
}

.tabbed-content h3 {
    font-size: 1.65em;
}

.expand-cell {
    cursor: pointer;
    width: 30px;
}

.parent-expand-color {
    background-color: coral !important
}

.parent-expand-color:hover {
    background-color: coral !important;
    filter: brightness(80%);
}

.parent-expand-color td {
    background-color: coral !important;
}

.expanding-color {
    border: 2px solid black !important
}

.react-resizable {
    max-width: 100%;
}

.react-resizable-handle {
    bottom: -10px;
    right: -10px;
}

.alert {
    z-index: 1002;
}

.noUMerge {
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    /* align vertical */
    color: red;
    background-color: #dcdedc;
}

.popover {
    z-index: 2004
}

.modal {
    z-index: 4000
}

.ReportForm {
    max-width: 800px;
    margin: auto;
    padding: 20px;

}

.ReportFormEntry {
    text-align: left;
    padding: 20px;


}

.smallimage {
    max-height: 50px !important;
    max-width: 100% !important;
    overflow: hidden;
}

.InventoryRestock .smallimage {
    max-height: 75px !important;
}

#PurchaseOrderHeaderBox .smallimage {
    float: right;
    height: 38px;
    width: 38px;
    margin-right: 5px;
    padding: 7px;
    background-color: #f8f9fa;
}


#PurchaseOrderHeaderBox .smallimage:hover {

    background-color: #e2e6ea;
}

.invTable .react-bootstrap-table-editing-cell {
    width: 5%
}

.orderTable .react-bootstrap-table-editing-cell {
    width: 3%
}

.editing-fba-note {
    width: 5% !important;
}

.donut-chart-example .rv-radial-chart__series--pie__slice:hover {
    stroke: #000 !important;
    stroke-width: 2px !important;
    transform: scale(1.05);
}

.TutorialImg {
    max-width: 100%;
    max-height: 500px;
}

.right-align {
    text-align: right;
}

.modal-footer {
    background-color: #fafafa
}

.nav-tabs {
    background-color: #fafafa;
}

.FinancialTabs a {
    color: #666;
}

.FinancialTabs .nav-link.active {
    border-bottom-color: #ff6200;

    border-bottom-width: 3px;
    border-radius: 2px;
    color: black;
}

.productTabs {
    width: "100%";
    background-color: white;

}

.productTabs.nav-tabs {
    border-bottom: none
}


.productTabs .nav-link {
    color: #666;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-top: 1px solid lightgray;
    text-align: left;
    background-color: #fafafa;
    border-bottom: 1px solid lightgray;
}

.productTabs .nav-link.active {
    color: black;
    border-top-color: #ff6200;
    border-right-color: lightgray;
    border-left-color: lightgray;
    border-top-width: 4px;

}

.RangePickerWrapper {
    text-align: left;
    padding: 15px;
}


.react-datepicker-wrapper .form-control {
    display: inline-block;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
    padding-top: .7rem !important;
}

.ItemModalDetailsForm .form-group {
    margin-bottom: .5rem;
}

.ItemModalDetailsForm .form-label {
    margin-bottom: .25rem;
}

.homeAlerts {
    font-size: 1.1rem;
    color: black;
    margin: 0px;
}

a.dropdown-item:hover {
    text-decoration: none;
}

.btn-link,
a {
    text-decoration: none;
}

a.nav-link:hover {
    text-decoration: none;

}


a:hover {

    text-decoration: underline;
}

.OverviewStats .row>* {
    width: inherit;
}

.tableNoBorder {
    border: none;
    border-bottom: 0 !important;
    border-top: 0 !important;
}


.NoRowFull .row>* {
    width: inherit;
}

.EditOrderQty .row>* {
    width: inherit;
}

.RowsNoFullWidth .row>* {
    width: auto;
}

.orderItemList .form-label {
    font-size: .9rem;
}


.orderItemList .CostInputEdit:focus {
    background-color: white;
}

.orderItemList .CostInputPlain:focus {
    outline: 0px;
}

.form-floating>label {
    opacity: 65%;
}

.warningIcon {
    font-size: 1.25em;
}

.buttonHeight {
    line-height: 38px;
}

.hoveredtablerow {
    background-color: rgba(0, 0, 0, 0.075) !important;
    font-weight: bold;
}

.LinkedItemModal .nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    background-color: #007bffba;
}

.LinkedItemModal .flex-column {
    width: 100%;
}

.LinkedItemModal .nav-fill .nav-item,
.nav-fill>.nav-link {
    text-align: left;
}

.modal-backdrop {
    z-index: 4000;
}

.SalesBarGraph {
    font-size: 1rem;
}

.SalesBarGraph .recharts-surface {
    margin-left: -35px;
}

.PLChart .recharts-default-legend {
    margin-top: 75px !important;
}

.custom-tooltip {
    border-radius: 4px;
    background: #3a3a48;
    color: #fff;
    font-size: 12px;
    padding: 7px 10px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 50%);
    text-align: left;
    white-space: nowrap;
    width: 100%;
    padding-right: 25px;

}

.custom-tooltip p {
    margin: 0px;
}

.custom-tooltip-title {
    font-weight: 700;
    white-space: nowrap;
}

.recharts-legend-item-text {
    color: black !important;
    font-size: 14px;
}

.graphDivBox .switch {
    float: right;
}

.gutter {
    background-image: url("data:image/svg+xml,%3Csvg width='2' height='14' viewBox='0 0 2 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%2342526E' fill-rule='evenodd'%3E%3Ccircle cx='1' cy='1' r='1'/%3E%3Ccircle cx='1' cy='5' r='1'/%3E%3Ccircle cx='1' cy='9' r='1'/%3E%3Ccircle cx='1' cy='13' r='1'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: left center;

}

.order_details_content {
    height: calc(100vh - 165px);
    overflow-y: auto;
    border: 1px solid lightgray;
    background-color: white;
}

#PurchaseOrderHeaderBox {
    padding-top: 10px;
    border-bottom: 1px solid lightgray;
}

.ViewOrderShow-true {
    display: none;
}

.HiddenUnlessPrint {
    display: none;
}

.pagination {
    margin-bottom: 0px;
}

.page-link {
    padding: .35rem .5rem;
}

.FrontPageWrapper {
    min-height: calc(100vh - 170px);
}


.narrowColumnCell {
    text-align: left;
}

.narrowColumnTitle {
    text-align: center;
    margin-bottom: 10px;
    font-size: 1rem;
}

.narrowColumnContentL1 {
    font-size: .8rem;
    color: #383c40;
}

.AddOtherItemSearch {
    width: auto
}

.FilterSection {
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
}


.FilterSection div {
    margin-left: 5px;
}

#filterListLabel {
    margin-bottom: 5px;
    display: block;
}

.RowNoStretch {
    align-items: flex-start;
    align-content: flex-start;
}


.FinancialSummaryBox {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid lightgray;
    background-color: #fafafa;
    height: 230px;
}

.FinancialSummaryBox tr {
    border: none;
}

.profitTabBox {
    padding-top: 10px;
    padding-left: 0px;
    margin-bottom: 10px;
    border-right: 1px solid lightgray;
    border-left: 1px solid lightgray;

    border-bottom: 1px solid lightgray;

    background-color: white;
}

.productTabRow .tab-content {
    padding-left: 0px;

}




@media print {
    body {
        background-color: white !important;
        margin-top: -75px;
    }

    .wrapper {
        background-color: white !important;
        overflow: hidden
    }

    #orderContainer {
        max-width: 90%;
    }

    .order_details_content {
        height: 100%;
        max-height: 100%;
        width: 100% !important;
        overflow-y: hidden !important;
        border: none;
    }

    .LeftOrderPanel {
        display: none
    }

    .table-responsive {
        overflow: visible;
    }

    .alert {
        display: none
    }

    .fixedTable {
        display: block;
    }

    .fixedTable tbody {
        height: 100%;
        width: 100%;
        overflow-y: hidden !important;
    }

    .fixedTable thead {
        width: 100%;
    }

    .fixedTable .itemUrlCol {
        display: none
    }

    .grayheader {
        display: none
    }

    .headerBox button {
        display: none
    }

    #PurchaseOrderHeaderBox {
        display: none
    }

    .gutter {
        display: none
    }

    .ViewOrderShow-true {
        display: block;
    }

    .HiddenUnlessPrint {
        display: block;
    }

    .tablerow {
        border: none;
    }

    .HideOnPrint {
        display: none;
    }

    .fullContainerViewOrder>tbody {
        height: 100% !important;
    }


    .fullContainerInventory>tbody {
        height: 100% !important;
    }

    #itemWrapper {
        margin-bottom: 25px;
    }

}

/*@media screen and (max-width: 1675px){
    .EditOrderQty{
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding-left:20px !important;
    }

    .EditOrderSKU{
        flex: 0 0 100.00%;
        max-width: 100.00%;
    }
}

@media screen and (max-width:915px){
    .EditOrderQty{
        flex: 0 0 100.00%;
        max-width: 100.00%;
        padding-left:20px !important;
    }

  
}
*/