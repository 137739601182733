.logo {
  width: 50px;
  height: auto;
  padding: 0;
  cursor: pointer;
}

 .grayscale-nav+a {
    color : rgba(255,255,255,.5);
    background-color: transparent;
    
}

.grayscale-nav+a:hover{
        color: rgba(255,255,255,.75);
        text-decoration: none;
}

.slide_down_header {
  border: none;
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 0 !important; 
  top: -200px;
  height: 51px;
  -webkit-box-shadow: 0 6px 4px rgba(3,27,78,.3);
  -moz-box-shadow: 0 6px 4px rgba(3,27,78,.3);
  box-shadow: 0 2.5px 4px rgba(3,27,78,.2);
  z-index: 1002;
}


.slide_down_header .logo {
  width: 50px;
  height: auto;
  padding: 0;
  cursor: pointer;
  
  
}

.static .navbar {
  background-color: transparent !important; 
}

.front_page_nav{
    width: 100%;
  z-index: 2003;
  background-image: none;
 
  position: absolute;
  border-bottom: none;
  box-shadow: none;
  padding-right:150px;
  padding-left:1rem;
  background-color: /*rgb(2 0 48);*/  rgb(255 98 0 / 1) ;
  color: #f4f4f4;
  padding-top:0;
  padding-bottom:0;
  
}



.navbar-dark .navbar-nav .nav-link{
  color:rgb(255 236 224 / 1);
  font-weight:500;
}

.slide_down_header  .nav-link{
  color:#f4f4f4 !important;
  font-weight:500;
 
}
.navbar-dark .navbar-nav .nav-link:hover{
  color: hsla(0,0%,100%,.75); 
}
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff;
}
.slide_out_cart{
    z-index:10000;
    width:400px;
    margin-top:75px;
    
    background-color:rgb(150,150,150,.5);
    
}


#totalCost{
  display:inline-block;
  margin-top: 10px;
 margin-left:140px;
 margin-bottom:8px
}

#headerBar{
  background-color: #fafafa90;
  border-radius: .25rem;
}

.itemlist{
    padding:20px;
    overflow-y: scroll;
    height:90vh;

    
}
.itemlist .form-label{
  font-size:.8rem
}

.itemlist .col-3{
  padding-right:5px;
  padding-left:5px;
}

.tightHeader{
  padding:5px
}
.align-right{
  text-align:right
}
.shownCart{
    transform:translate(calc(100vw - 400px),0);
     transition: transform .30s linear
}
.hiddenCart{
     transform:translate(calc(100vw),0);
      transition: transform .30s linear
      
}
.item{
    margin-top: 10px;
    margin-bottom: 10px;
}

.OrderItem{
  margin-top: 0px;
  margin-bottom: 15px;
}

.updateItems{
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius:0px;

}
.cartIcon{
    font-size:1.5rem;
    color:rgba(93,3,3)
   
}
.cartIcon:hover{
  font-size:1.5rem;
  color:rgba(93,3,3, .5)
 
}

.profileNav .dropdown-toggle:after {
  display: none;
}

.dropdown-menu{
  min-width:8.5rem;
}

.ButtonTop:hover .cartIcon{
  font-size:1.5rem;
  color:rgba(93,3,3, .5)
 
}
.ButtonTop:focus{
  box-shadow:none
}

.ButtonLeft{
    position:absolute;
    right:50px;
    top: 11px;
    z-index: 10000;
}

.ButtonRight{
  position:absolute;
  right:10px;
  top: 11px;
  z-index: 10000;
}

.Footer{
  padding:50px;
  width:100%;
  height:100px;
  background-color:rgb(255 98 0 / 1) ;
  color:rgb(255 236 224 / 1);
  font-weight:500;
  font-size:1rem;
}
.loadMoreUpdates{
  text-align: center;
}
.loadMoreUpdates:hover{
  background-color: rgba(0,0,0,.09);
}

.HistoryAllLink{
  color:blue;
  padding: 0.5rem 1rem;
  width:100px;
  float:right;
}


@media print  { 
  .Footer{
    display:none
  }
  .front_page_nav{
    display:none
  }
  .slide_down_header{
    display: none;
  }
  .customtable{
    box-shadow: none;
    margin:0px;
    font-size:.85rem;
  }
 
  
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}
.fullContainerViewOrder .container,  body {
  min-width: 992px!important;
}

*, :after, :before {
  text-shadow: none!important;
  box-shadow: none!important;
}

.col-sm-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.fullContainerViewOrder td,.fullContainerViewOrder th{
  border:1px solid lightgray !important;
}
}
