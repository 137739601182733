.loginForm{
    margin-left: auto;
    margin-right: auto;
    width:50%;
    max-width:500px;
}
h1{
    font-size:1.5rem;
    margin-bottom:20px;
}
.error{
    margin: 15px;
    color:red;
}