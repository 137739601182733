.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color:#FaFaFa;
  height: 100%;
  /*display: flex;
  flex-direction: column;*/
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000cf;
  padding-top:70px;
  /*padding-bottom:100px;*/
}
.customtable{
  width:90%;
  margin:auto;

  box-shadow: 1px 1px 4px 0px lightgrey;;
}
.SalesStats{
  width:100%;
  
}
.left-align{
  text-align:left;
}

.text-right{
  width: 100%;
  text-align:right;
}
.tablerow{
  padding:10px;
  
  background-color: white;
  border:1px solid lightgray;
  margin-bottom:5px;
  
}
.headerBox h1{
  margin:0px 0px;
}
.headerBox{
  width:100%;
  margin:0px;
  padding:10px 20px;
  background-color: white;
  border-top:1px solid lightgray;
  border-right:1px solid lightgray;
  border-left:1px solid lightgray;
  border-bottom:1px solid lightgray;


}
.grayheader{
  background-color: #fafafa;
}
.lightgrayheader{
  background-color: #fcfcfc;
}
.widetable{
  width:98%;
}
.fulltable{
  width:100%;
}
.narrowTable{
  width:90%;
  max-width:800px;
}
.wrapper{
     background-color:#FaFaFa;
     
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.salesFigures{
  color:rgb(255 236 224 / 1);
  font-size:1.2rem;
  width:100%;
  text-align:center;
  margin-left:-15px;
}
.sfLarge{
  font-size:1.3rem;
  margin-left:0px;
}
.frontArrow{
  font-size:1.2rem;
  margin-top:5px;
  margin-right:10px
}
.frontArrowBig{
  font-size:1em;
  margin-right:5px;
  width:.95em
}
.positiveArrow{
  color:green;
}
.negativeArrow{
  color:#8a0505;
}
.App-header h1{
  font-size:2rem
}
.App-header h2{
  font-size:1.75rem
}

.dot {
  height: 190px;
  width: 190px;
  background-color: rgb(255 98 0 / 1) ;
  border-radius: 50%;
  display: inline-block;
  padding: 25px 5px;
  z-index:1001;
  position: absolute;
  
}
.dot:hover{
  transform: scale(1.05)
}
.PieWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

.TopColumn{
  margin-bottom: 10px;
}


@media screen and (max-width: 492px){
  .MidTopColumn{
    border-right: none !important;
    margin-right:2px
  }

}

@media screen and (max-width: 502px){
  .PieMidColumn{
    border-right: none !important;
    margin-right:2px
  }

}
@media screen and (max-width: 992px){
.CollapseOnSmall .donut-chart-example{
  display:none
}
.CollapseOnSmall .dot{
  height: inherit;
  width: inherit;
  background-color: white;
  border-radius: 0%;
  display: inline-block;
  padding: 5px 5px;
  z-index:1000;
  position: relative;
}
.CollapseOnSmall .salesFigures{
  color:gray;
  font-size:1rem;
  width:inherit;
  text-align:left;
  margin-left:0px;
}
.CollapseOnSmall .dot:hover{
 transform:scale(1)
}
.CollapseOnSmall .frontArrow{
  font-size:1rem;
  margin-top:5px;
  margin-right:10px
}

.CollapseOnSmall .PieWrapper{
  display: flex;
  align-items: left;
  justify-content: left;
  min-height: 0px;
  
}

.CollapseOnSmall .PieColumn{
  margin-bottom: 10px;
}
.CollapseOnSmall.colCollapse{
  display:none;
}
}