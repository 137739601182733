.tr {
  display: flex;
}

tr,
.tr {
  width: fit-content;
  height: 30px;
}

th,
.th,
td,
.td {
  box-shadow: inset 0 0 0 1px lightgray;
  padding: 0.25rem;
  text-align: left;
}

th,
.th {
  padding: 2px 4px;
  font-weight: bold;
  position: relative;
  text-align: left;
  height: 30px;
}

td,
.td {
  height: 30px;
}

.no-hover {
  box-shadow: none !important;
}

.lightningFixedTable>tbody {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden
}

.lightningTable>thead {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.lightningFixedTable>thead {
  display: table;
  width: calc(100% - 17px);
  table-layout: fixed;
}

.lightningTable>tbody>tr {
  display: table;
  width: 100%;
  table-layout: fixed
}


.lightningTable th {
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #e1e1e1;
  background-color: #f0f0f0;
}

.resizer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 5px;
  background: rgba(0, 0, 0, 0.5);
  cursor: col-resize;
  user-select: none;
  touch-action: none;
}

.resizer.isResizing {
  background: black;
  opacity: 1;
}

.tableCellEdit {
  padding-top: 0px
}

.tableCellEdit:focus {
  color: #212529 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.375rem;
  padding: 5px;
}



.expandingButton {
  border: none;
  background-color: rgba(0, 0, 0, 0.0);
  height: 33.5px;
}

.parent-expanded {
  background-color: rgb(255, 98, 0, .5) !important;
}

.expanded-row {
  border: 1px solid gray;
}


.hideColumn {
  display: none;
}


.lightningTable tbody,
td,
tfoot,
th,
thead,
tr {
  border: none;
  border-top: .25px solid #c1c1c1;
}

.lightningTable tr:first-child {
  border: none;
}

.lightningTable tr:last-child {
  border-bottom: .25px solid #c1c1c1;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  .resizer:hover {
    opacity: 1;
  }
}

.tableCellEdit:focus::placeholder {
  color: transparent;
}


@media screen and (max-width:576px) {
  .pageSizeSelect {
    clear: both !important;
    float: none !important;
    margin: auto;
  }

  .rowDisplay {
    text-align: center;
    margin-bottom: 10px;
  }


}

@media print {
  .lightningTable tbody {
    height: 100% !important;
    overflow: hidden;
  }

  .lightningTable thead {
    width: 100% !important;
  }
}