
body {
    margin: 0;
    padding: 0;
    background: #7C3238;
    
  }
  
  .search-box {
    
    background: #fafafa;
    height: 40px;
    border-radius: 40px;
    padding-left: 10px;
    padding-right:10px;
    
  }

  .searchWrapper:hover .search-box-active {
    
    background: #fafafa;
    height: 40px;
    border-radius: 20px 20px 0px 0px;
    padding-left: 10px;
    padding-right:10px;
    
  }

  
  
  .searchWrapper:hover  .search-text{
    width: calc(100% - 60px);
    
  }
  
  .searchWrapper:hover .search-btn{
    background: #fafafa;;
    color: black;
    
  }
  
  .search-btn {
    color: rgba(93,3,3);
    float: left;
    width: 40px;
    height: 40px;
    transform: translate(-25%, 0%);
    border-radius: 50%;
    background: #fafafa;;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;
    
  }
  .search-btn > i {
    font-size: 30px;
  }
  
  .search-text {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: black;
    font-size: 16px;
    font-weight: normal;
    
    line-height: 40px;
    width: 0px;
    
  /*   width: 250px; */    
    
  }
  .search-results,.search-results-active{
    display:none
  }

  .searchWrapper{
    position:relative;
    transition:  0.4s;
    width: 60px;
    z-index: 20000;
    margin-top:3px;
  }
  .searchWrapper:hover .search-results-active{
    display:block !important;
    height: 400px;
    background-color: #fafafa;
    position: absolute;
    width: 315px;
    color:black;
    padding:0px 5px 5px 5px;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
  }

  .searchWrapper:hover {
    width:315px;
  }


  .close-btn{
    display:none;
  }

  .searchWrapper:hover .search-box-active .close-btn{
    display:flex;
    font-size:.75rem;
    width:15px;

  }

  .search-results-active hr{
    margin-top:0px;
  }
  

  .search-btn{
    cursor:default;
  }

  .SearchResultItem{
    margin:5px;
    padding:5px;
  }

  .SearchResultItem:hover{
    background-color:#f0f0f0;
    cursor:pointer;
  }

  .SearchResultItem a:hover{
    text-decoration: none;
  }

  @media screen and (max-width: 768px) {
    .searchWrapper{
        width:100% !important

    }

    .search-text{
        width: calc(100% - 60px) !important;
        
      }
      .search-results-active{
        width:100% !important;
      }
    }